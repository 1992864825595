import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Edit(props) {
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState({
    nome_da_empresa: '', email: '', telefone: '',  
  });
    
  // Open the modal form and update the customer state
  const handleClickOpen = () => {
    setCustomer({
      nome_da_empresa: props.data.row.nome_da_empresa,
      email: props.data.row.email,
      telefone: props.data.row.telefone,
      endereco_cidade: props.data.row.endereco_cidade,
      endereco_numero_rua: props.data.row.endereco_numero_rua,
      endereco_estado: props.data.row.endereco_estado,
      endereco_zipcode: props.data.row.endereco_zipcode,
    })      
    setOpen(true);
  }
  
  // Close the modal form 
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setCustomer({...customer, 
      [event.target.name]: event.target.value});
  }

  // Update customer and close modal form 
  const handleSave = () => {
    props.update(customer, props.data.id);
    handleClose();
  }

  const isDisabled = () => {
    if(
       customer.nome_da_empresa === ''
    || customer.endereco_cidade === ''
    || customer.endereco_numero_rua === ''
    || customer.endereco_estado === ''
    || customer.endereco_zipcode === ''
     ){
      return true;
    }

    return false;
  }
 
  return(
    <div>
      <IconButton onClick={handleClickOpen}>
          <EditIcon telefone="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>Edit customer</DialogTitle>
        <DialogContent>
        <Stack spacing={2} mt={1}>
              <TextField label="Customer Name (*)" name="nome_da_empresa" autoFocus
                variant="standard" value={customer.nome_da_empresa} 
                onChange={handleChange}/>
              <TextField label="Customer Street (*)" name="endereco_numero_rua" 
                variant="standard" value={customer.endereco_numero_rua} 
                onChange={handleChange}/>
              <TextField label="Customer City (*)" name="endereco_cidade" 
                variant="standard" value={customer.endereco_cidade} 
                onChange={handleChange}/>
              <TextField label="Customer State (*)" name="endereco_estado" 
                variant="standard" value={customer.endereco_estado} 
                onChange={handleChange}/>  
              <TextField label="Customer Zip Code (*)" name="endereco_zipcode" 
                variant="standard" value={customer.endereco_zipcode} 
                onChange={handleChange}/>
              <TextField label="Customer Email" name="email" 
                variant="standard" value={customer.email} 
                onChange={handleChange}/>
              <TextField label="Customer Phone" name="telefone" 
                variant="standard" value={customer.telefone} 
                onChange={handleChange}/>  
            </Stack>
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Fields marked with (*) are required.
          </Typography>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isDisabled()} onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>            
    </div>
  );
}

export default Edit;