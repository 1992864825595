import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Add(props) {
  var initialState = {
    nome_da_empresa: '',
    email: '',
    telefone: '', 
  };
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialState);

  // Open the modal form
  const handleClickOpen = () => {
    setCustomer(initialState);
    setOpen(true);
  };
    
  // Close the modal form 
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChange = (event) => {
    setCustomer({...customer, [event.target.name]: event.target.value});
  }

  // Save customer and close modal form 
  const handleSave = () => {
    props.addObject(customer);
    setCustomer({
      nome_da_empresa: '',
      email: '',
      telefone: '', 
    });
    handleClose();
  }

  const isDisabled = () => {
    if(
       customer.nome_da_empresa === ''
    || customer.endereco_cidade === ''
    || customer.endereco_numero_rua === ''
    || customer.endereco_estado === ''
    || customer.endereco_zipcode === ''
     ){
      return true;
    }

    return false;
  }
  
  return(
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        New customer
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>New customer</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
              <TextField label="Customer Name (*)" name="nome_da_empresa" autoFocus
                variant="standard" value={customer.nome_da_empresa} 
                onChange={handleChange}/>
              <TextField label="Customer Street (*)" name="endereco_numero_rua" 
                variant="standard" value={customer.endereco_numero_rua} 
                onChange={handleChange}/>
              <TextField label="Customer City (*)" name="endereco_cidade" 
                variant="standard" value={customer.endereco_cidade} 
                onChange={handleChange}/>
              <TextField label="Customer State (*)" name="endereco_estado" 
                variant="standard" value={customer.endereco_estado} 
                onChange={handleChange}/>  
              <TextField label="Customer Zip Code (*)" name="endereco_zipcode" 
                variant="standard" value={customer.endereco_zipcode} 
                onChange={handleChange}/>
              <TextField label="Customer Email" name="email" 
                variant="standard" value={customer.email} 
                onChange={handleChange}/>
              <TextField label="Customer Phone" name="telefone" 
                variant="standard" value={customer.telefone} 
                onChange={handleChange}/>  
            </Stack>
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Fields marked with (*) are required.
          </Typography>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isDisabled()} onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>            
    </div>
  );
}

export default Add;
