import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox, FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import axios from 'axios';
import env from '../../config';

function ProjectDialog({
    open,
    onClose,
    saveSuccess,
    object,
    setObject
}) {
    const [customers, setCustomers] = useState([]);
    const backendUrl = env.backendUrl;

    useEffect(() => {
        fetchCustomers();
    }, []);

    const isDisabled = () => {
        return !object.data_projeto_inicio || !object.responsavel || !object.email || !object.cliente_id;
    };

    const fetchCustomers = () => {
        axios.get(`${backendUrl}/clientes`)
            .then(res => setCustomers(res.data))
            .catch(err => console.error(err));
    };

    const handleChange = (event) => {
        setObject({ ...object, [event.target.name]: event.target.value });
    };

    const handleClearDate = () => setObject({ ...object, data_projeto_fim: null });

    const handleDateChange1 = (value) => {
        const date = value ? dayjs(value).format('YYYY-MM-DD') : null;
        setObject({ ...object, data_projeto_inicio: date });
    };

    const handleDateChange2 = (value) => {
        const date = value ? dayjs(value).format('YYYY-MM-DD') : null;
        setObject({ ...object, data_projeto_fim: date });
    };

    const handleSave = () => {
        let url = env.backendUrl + '/projetos';
        const data = JSON.stringify(object);

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        if(object.id){
            url = env.backendUrl + '/projetos/' + object.id;

            axios.put(url, data, config)
            .then(response => {
                if (response.status === 200) {
                    // fetchObjects();
                    saveSuccess();
                }
                else {
                    alert('Something went wrong!');
                }
            })
            .catch(err => console.error(err))
        }else{
            axios.post(url, data, config)
            .then(response => {
                if (response.status === 200) {
                    // fetchObjects();
                    saveSuccess();
                }
                else {
                    alert('Something went wrong!');
                }
            })
            .catch(err => console.error(err))
        }

        
    }

    // // Função para salvar as alterações
    // const handleSave = () => {
    //     if (!selectedProject) return;

    //     const url = `${env.backendUrl}/updateProject/${selectedProject.id}`;
    //     axios.put(url, selectedProject)
    //         .then((response) => {
    //             if (response.status === 200) {
    //                 // Atualiza a lista de projetos após salvar
    //                 fetchObjects();
    //                 setOpen(false); // Fecha o modal
    //             }
    //         })
    //         .catch((err) => {
    //             console.error('Erro ao salvar o projeto:', err);
    //         });
    // };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>New project</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    <TextField
                        select
                        label="Customer (*)"
                        name="cliente_id"
                        variant="standard"
                        value={object.cliente_id}
                        onChange={handleChange}
                    >
                        {customers.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.nome_da_empresa}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Project title (*)"
                        name="titulo"
                        variant="standard"
                        value={object.titulo}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Supervisor (*)"
                        name="responsavel"
                        variant="standard"
                        value={object.responsavel}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Bill Box"
                        name="bill_box"
                        variant="standard"
                        value={object.bill_box || '' }
                        onChange={handleChange}
                    />
                    <TextField
                        label="PO Box Number"
                        name="po_box_numero"
                        variant="standard"
                        value={object.po_box_numero || '' }
                        onChange={handleChange}
                    />
                    <TextField
                        label="PO Box City"
                        name="po_box_cidade"
                        variant="standard"
                        value={object.po_box_cidade || '' }
                        onChange={handleChange}
                    />
                    <TextField
                        label="PO Box State"
                        name="po_box_estado"
                        variant="standard"
                        value={object.po_box_estado || '' }
                        onChange={handleChange}
                    />
                    <TextField
                        label="PO Box ZIP Code"
                        name="po_box_zip"
                        variant="standard"
                        value={object.po_box_zip || '' }
                        onChange={handleChange}
                    />
                    <TextField
                        label="Description"
                        name="descricao_do_projeto"
                        variant="standard"
                        value={object.descricao_do_projeto}
                        onChange={handleChange}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start"
                            name="data_projeto_inicio"
                            value={object.data_projeto_inicio ? dayjs(object.data_projeto_inicio) : null}
                            onChange={handleDateChange1}
                            variant="standard"
                        />
                        <DatePicker
                            label="End"
                            name="data_projeto_fim"
                            value={object.data_projeto_fim ? dayjs(object.data_projeto_fim) : null}
                            onChange={handleDateChange2}
                            variant="standard"
                        />
                        <Button onClick={handleClearDate} variant="outlined" style={{ marginLeft: '10px' }}>
                            Clear End Date
                        </Button>
                    </LocalizationProvider>
                    <TextField
                        label="Invoice Email (*)"
                        name="email"
                        variant="standard"
                        value={object.email}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Contract"
                        name="contrato"
                        variant="standard"
                        value={object.contrato || ''}
                        onChange={handleChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!object.enviar_invoice} // ou use object.enviar_invoice === 1
                                onChange={(e) => handleChange({ target: { name: 'enviar_invoice', value: e.target.checked } })}
                                name="enviar_invoice"
                            />
                        }
                        label="Send Invoice"
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Typography variant="body2" color="textSecondary" mb={2}>
                    Fields marked with (*) are required.
                </Typography>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={isDisabled()} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProjectDialog;
